@import "./../variables/index.scss";

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  max-height: 100vh;
  overflow-y: hidden;
}
.welcomeText {
  color: rgb(81,67,144);
  // color: linear-gradient(90deg, rgba(81, 67, 144, 1) 28%, rgba(89, 74, 158, 1) 74%, rgba(122, 110, 177, 1) 100%);
}
.navbar-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  height: 4rem;
  background-color: #fff;
  width: 100%;
  z-index: 999;
  border-bottom: 1px solid #eee;
}

.main-page {
  //margin-top: 4.5rem;
  display: flex;
  //justify-content: space-between;
  position: relative;
  flex-direction: row;
}
.sidebar-wrapper {
  width: 225px;
  // margin-top: 4.5rem;
  //position: fixed;
  //height: 60vh !important;
}

[data-bg="collapsed"].sidebar-wrapper {
  width: 80px !important;
}

.main-content-wrapper {
  flex: 1;
  height: calc(100vh - var(--navbar-height));
  padding: 3rem 2.5rem;
  //    max-height: 100vh;
  overflow: auto;
  background-color: $lighterGrey;

  .page {
    display: flex;
    flex-direction: column;
  }
}

.MuiPaper-root {
  // position: relative !important;
  //height: calc(80vh - 4.5rem);
}

.module-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.module-name {
  position: relative;
  min-height: 30px;
  //padding-left: 36px;
  padding-top: 16px;
  padding-bottom: 12px;

  /* Desktop / H3 */

  font-family: $font-face-medium;
  font-style: normal;
  font-weight: $font-weight-heavy;
  font-size: $font-size-massive;
  line-height: 115%;
  /* identical to box height, or 43px */

  display: flex;
  align-items: center;
  letter-spacing: -0.002em;

  /* Grayscale / Gray 4 */

  color: $gray7;
}

$box-y-value: 3px;

.module-action {
  display: flex;
  align-items: center;
  .module-add-button {
    /* Desktop / Button */

    /* Auto layout */

    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    //margin-right: 378px;
    //margin-top: 12px;
    cursor: pointer;

    // position: absolute;
    // width: 196px;
    // height: 44px;
    // right: 379px;
    // //top: 36px;

    /* Brand / Secondary */

    background: $secondary;
    border-radius: 24px;
    border: 0px;

    //styleName: Desktop / Button;
    font-family: $primary-font;
    font-size: 1rem;
    font-weight: $font-weight-heavy;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $primary;
    box-shadow: 0px $box-y-value 0 0 $primary;

    &.cancel {
      background-color: transparent;
      border: 0.5px solid $secondary;
      box-shadow: 0px $box-y-value 0 0 $secondary;
    }

    &.disabled,
    &:disabled {
      color: #8c8c8c;
      background-color: #dedede;
      box-shadow: none;
    }
  }

  .search-module {
    display: flex;
    margin-left: 1rem;
    align-items: center;
    background: white;
    height: 40px;
    width: 521px;
    border: 1px solid #b6b6b6;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    &:focus-visible {
      outline: 1px solid red;
    }

    input {
      border: 0;
      position: relative;
      padding-right: 322px;
      margin: auto;
      &:focus-visible {
        outline: none;
      }
    }

    i {
      position: absolute;
      right: 4px;
      color: $grayscale-white;
      margin-left: 0;
    }
  }

  button:active {
    box-shadow: none !important;
    transform: translateY(2px);
    border: 0.5px solid #926b087a;
  }
}

.module-approve-button {
  /* Desktop / Button */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  justify-content: center;

  height: 44px;

  /* Brand / Secondary */

  background: $secondary;
  border-radius: 24px;
  margin-right: 1rem;
  border: 0px;
  color: $brand;

  //styleName: Desktop / Button;
  font-family: $primary-font;
  font-size: $font-size-medium;
  font-weight: $font-weight-heavy;
  line-height: 24px;
  letter-spacing: 0.02em;

  box-shadow: 0px $box-y-value 0 0 $primary;

  &:disabled {
    color: #8c8c8c;
    background-color: #dedede;
    box-shadow: none !important;
  }
}

.module-delete-button {
  /* Desktop / Button */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 40px;
  height: 40px;

  /* Status/Error/error-500 (base) */

  background: $error;
  border-radius: 24px;

  box-shadow: 0px $box-y-value 0 0 $primary;

  i {
    color: $grayscale-white !important;
  }
}

.navbar-wrapper.home_navbar {
  position: static;
}

.sidebar_home {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - var(--navbar-height));
  height: 100%;
  .sidebar {
    flex: 1;
  }
}

.toast-container {
  height: 30px;
}
.toast-body {
  font-size: 1rem;
}
