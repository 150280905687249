.heading-container {
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    margin: 0 !important;
    padding-right: 0.7rem;
  }
}

* {
  font-family: "Nunito-Regular", sans-serif;
}

.status_position {
  width: 88%;
  margin: auto;
  padding-bottom: 1rem;
}
