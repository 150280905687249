@import "/src/assets/style/variables/typography";
@import "/src/assets/style/variables/index";
@import "/src/assets/style/variables/colors";

.patient-container {
  padding: 0.25rem 4.25rem;
}
$icon-dimension: 35px;
.calender-icon-history {
  background: $primary;
  width: $icon-dimension;
  height: $icon-dimension;
  border-radius: 50%;
  display: flex;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  color: white;
  // margin: auto;
  padding: 10px;

  &-svg {
    width: auto;
    height: 20px;
  }
}

.patient-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;
}

.shampoo-table {
  width: 100%;
  border: 1px solid #2f2f3c59;
  border-radius: 5px;
  max-height: 232px;
  overflow-y: auto;

  th,
  td {
    max-width: 70px;
    border: 1px solid #2f2f3c59;
    font-family: "Nunito-Regular", sans-serif;
  }
}

.product-btn {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 10px 24px 10px 20px;

  cursor: pointer;

  background: $secondary;
  border-radius: 24px;
  border: 0px;

  font-family: $primary-font;

  font-weight: $font-weight-heavy;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: $primary;
}
