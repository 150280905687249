@import "../../../assets/style/variables/index.scss";
html {
  body {
    font-size: 1.25rem;
  }
}

.report__layout {
  margin: 1rem 5% 0% 5%;
  padding-bottom: 1rem;

  &__header {
    font-weight: bold;
    color: $primaryLight;
    margin-bottom: 1rem;
  }

  &__header_container {
    display: flex;
    justify-content: space-between;
    font-weight: bold;

    h2,
    h3,
    h4 {
      margin: 0;
    }
    .date {
      font-size: 1.2rem;
    }
  }
  &__patient__details {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: 1rem 0rem;
    font-size: 1.2rem;
  }
  &__comments {
    // margin-bottom: 2rem;
    // font-size: 1.4rem;
    word-wrap: break-word;
  }

  &__img__data {
    // display: grid;
    // border: 1px solid #2f2f3c;
    // grid-template-columns: 1fr 1fr;
    padding: 1.5%;
    margin-top: 2rem;
    align-items: baseline;
    h2 {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  &__buttons {
    margin: 3rem 0rem;
    display: flex;
    justify-content: space-between;
  }
}

.btn-generate {
  display: flex;
  column-gap: 1rem;
}

.comments {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  h4 {
    font-size: 1rem;
    color: #636363;
  }
  & > div {
    text-align: justify;
    margin-bottom: 0.5rem 0 0.8rem;
  }
}

//pdf modal

.modal-content {
  width: 100%;
  height: 100%;
  background-color: #323639;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.canvas-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 1rem;

  & > div {
    width: 100%;
  }
}
