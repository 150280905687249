@import "/src/assets/style/variables/typography";
@import "/src/assets/style/variables/index";
@import "/src/assets/style/variables/colors";

.referralview_horizontal_texts {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-family: $primary-font;
  color: $grayscale-black;
  h4 {
    margin-top: 08px;
  }
  h6 {
    margin-top: 04px;
  }
}
.back-icon {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 12px;
  column-gap: 10px;
}
