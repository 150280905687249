@import "../../assets/style/variables/index";

.reviews-row {
  display: flex;
  justify-content: space-between;
}

.review-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  justify-content: center;
  position: static;
  font-family: Nunito-Regular;
  font-style: normal;
  width: 60px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fafafa;
}
.review-warning {
  padding: 4px 12px;
  background: $secondary;
  border-radius: 25px;
}
.review-error {
  padding: 4px 12px;
  background: $error;
  border-radius: 25px;
}
.review-no {
  padding: 4px 12px;

  // border-radius: 25px;
  color: black;
}
