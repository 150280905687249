@import "../../assets/style/variables/index";

.login-page {
  margin: 0;
  padding: 0;
  max-width: unset;

  $logo-width: 200px;

  .login-page-logo {
    width: $logo-width;
    height: $logo-width;
  }
}

.brand-section,
.form-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-section {
  flex: 0.35;
}

.brand-section {
  flex: 0.65;
  background-color: $primary;
  // background-color: #535adf;
  span {
    color: #fff !important;
  }
}

.form-group {
  margin: 0.75rem 0;
  font-size: 1rem;
}

.keep-logged-in-text {
  font-size: 1rem !important;
}

.login-form-container {
  flex: 1;
  max-width: calc(0.4 * 100vh);
}
