.employee-form-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 24px;
    min-width: 90px;
    height: 44px;
    border: 0px;

    /* Brand / Secondary */

    background: $secondary;
    border-radius: 24px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;

    font-family: $primary-font;
    font-style: normal;
    font-weight: $font-weight-heavy;
    font-size: $font-size-mediumPlus;
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 0.02em;

    /* Brand / Primary */

    color: $primary;
}

.employee-form {
    .MuiFormGroup-root {
        display: flex;
        flex-direction: row;
    }
}

.employee-approve-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 24px;

    /* Brand / Secondary */

    background: $secondary;
    border-radius: 24px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 16px;

    color: $primary;

}

.employee-view {
    .MuiFormGroup-root {
        display: flex;
        flex-direction: row;
    }
    .preview-label {
        font-family: $primary-font;
        font-style: normal;
        font-weight: $font-weight-light;
        font-size: $font-size-medium;
        line-height: 22px;
        /* identical to box height */

        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        /* Grayscale / Black */

        color: $grayscale-black;
    }

    .preview-value {
        font-family: $primary-font;
        font-style: normal;
        font-weight: $font-weight-lightHeavy;
        font-size: $font-size-mediumPlus;
        line-height: 150%;
        /* identical to box height, or 27px */

        display: flex;
        align-items: center;
        letter-spacing: 0.005em;

        margin-left: 1rem;
        margin-bottom: 1.5rem;

        /* Grayscale / Black */

        color: $grayscale-black;

    }
}

.section-text {
    font-family: $primary-font;
    font-style: normal;
    font-weight: $font-weight-heavy;
    font-size: $font-size-largePlus;
    line-height: 130%;
    /* identical to box height, or 34px */

    display: flex;
    align-items: center;

    /* Grayscale / Gray 3 */

    color: $grey;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 24px 0px;
}