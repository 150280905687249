@import "../../../assets/style/style.module.scss";

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin addBtn() {
  .add-btn {
    border: 1px solid $secondary;
    background: none;
    border-radius: 24px;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    color: $secondary;
    font-size: 1.2rem;
    img {
      margin-right: 0.5rem;
    }
  }
}

.location-employees {
  .add-form-btn-container {
    @include flexCenter();
    margin: 2rem 0;
  }

  @include addBtn;
  .location-entry {
    $headerColor: $primary;
    .location-header-container {
      @include flexCenter();
      justify-content: space-between;
      background: $headerColor;
      margin-bottom: 0.8rem;
      padding: 0.8rem;
      border-radius: 12px;
      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .location-name {
      margin: 0;
      flex: 1;
      padding: 0 1rem;
      cursor: pointer;
      color: #fff;
    }

    .trashout,
    .caret {
      cursor: pointer;
    }
    .trashout svg {
      color: $secondary;
    }

    .location-form-container {
      padding: 1rem;
      margin-top: -1rem;
      margin-bottom:1rem;
      border: 0.5px solid $headerColor;
      border-top: none;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    .location_id {
      width: 50%;
    }
  }
  .email-list {
    display: flex;
    flex-wrap: wrap;
    & > div {
      margin-right: 0.5rem;
    }

    & div:last-child {
      margin-right: 0;
    }
  }

  .location_btn_group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 1rem;

    @include addBtn();

    .add-btn {
      font-size: 1rem;
      padding: 10px 24px;
      height: 44px;
    }
  }

  .employee-search {
    position: relative;

    input {
      padding-right: 2rem;
    }

    .search-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    &-info {
      color: #123;
    }
  }

  .user-profile {
    &-container {
      display: grid;
      grid-template-columns: 2fr auto;
      grid-template-areas: "name name" "address address" "license license" "access-code action";
      max-width: 300px;
      padding: 0.5rem 1rem 1rem;
      column-gap: 1rem;

      margin-top: 1rem;
      border: 1px solid $primary;
      border-radius: 12px;
      cursor: pointer;
      box-shadow: 2px 2px 5px #594a9e38;
    }
    &-name {
      font-size: 1.2rem;
      grid-area: name;
      // margin: 0;
      color: $primary;
    }
    &-action {
      grid-area: action;
      align-self: center;
      // margin-bottom: 5px;
      display: flex;
      background: white;
      border: 1px solid $secondary;
      box-shadow: 2px 2px 0px #fdba12; // height: 2rem;

      &:active{
        box-shadow: none;
      }

      .add {
        font-size: 1.5rem;
        color: $primary;
      }
    }
    &-address {
      font-size: 0.8rem;
      grid-area: address;
      color: #686868;
    }
    &-license {
      font-size: 1rem;
      grid-area: license;
    }
    &-access-code {
      grid-area: access-code;
      // margin-top: 0.8rem;
    }
  }

  .employee-list {
    border: 1px solid $primary;
    thead tr {
      background-color: $primary;
      color: #fff;
    }

    th,
    td {
      padding: 0.5rem 0.8rem;
      border-bottom: 0.5px solid $primary;
    }
  }
}
