@font-face {
    font-family: 'MHCFont';
    src: local('MHCFont'), url('../../mhc-theme/fonts/myhairscope-webfont-v2_4.ttf') format('truetype');
    /* other formats include: 'woff2'; 'truetype; 'opentype';
                              'embedded-opentype'; and 'svg' */
  }
  
@font-face {
  font-family: 'Nunito-Regular';
  src: local('Nunito-Regular'), url('../../mhc-theme/fonts/Nunito-Regular.ttf') format('truetype');
  /* other formats include: 'woff2'; 'truetype; 'opentype';
                            'embedded-opentype'; and 'svg' */
}

$primary-font: Nunito-Regular;
$icon-font: MHCFont;

//font-size
$font-size-smaller: 12px;
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-tiny: 10px;
$font-size-mediumPlus: 18px;
$font-size-large: 20px;
$font-size-largePlus: 24px;
$font-size-massive: 32px;
$font-size-massivePlus: 36px;

//icon-font-size

$icon-font-size-tiny: 20px;
$icon-font-size-smaller: 24px;
$icon-font-size-small: 28px;
$icon-font-size-medium: 32px;
$icon-font-size-mediumPlus: 36px;
$icon-font-size-large: 40px;
$icon-font-size-largePlus: 48px;
$icon-font-size-massive: 64px;

//font-weight

$font-weight-light: 400;
$font-weight-medium: 500;
$font-weight-lightHeavy: 600;
$font-weight-heavy: 700;

//font-face

$font-face-medium: "Nunito-Regular";
$font-face-mediumItalic: "Nunito-Italic";
$font-face-bold: "Nunito-Bold";
$font-face-boldItalic: "Nunito-BoldItalic";
$font-face-light: "Nunito-Light";
$font-face-ligthItalic: "Nunito-LightItalic";


:export {
    primaryFont: Nunito-Regular;
    iconFont: MHCFont;

    primaryFont: Nunito-Regular;
    iconFont: MHCFont;
    //font-size
    fontSizeSmaller: $font-size-smaller;
    fontSizeSmall: $font-size-small;
    fontSizeMedium: $font-size-medium;
    fontSizeTiny: $font-size-tiny;
    fontSizeMediumPlus: $font-size-mediumPlus;
    fontSizeLarge: $font-size-large;
    fontSizeLargePlus: $font-size-largePlus;
    fontSizeMassive: $font-size-massive;
    //icon-font-size
    iconFontSizeSmaller: $icon-font-size-smaller;
    iconFontSizeSmall: $icon-font-size-small;
    iconFontSizeTiny: $icon-font-size-tiny;
    iconFontSizeMedium: $icon-font-size-medium;
    iconFontSizeMediumPlus: $icon-font-size-mediumPlus;
    iconFontSizeLarge: $icon-font-size-large;
    iconFontSizeLargePlus: $icon-font-size-largePlus;
    iconFontSizeMassive: $icon-font-size-massive;
    //font-weight
    fontWeightLight: $font-weight-light;
    fontWeightMedium: $font-weight-medium;
    fontWeightHeavy: $font-weight-heavy;
    //font-face
    fontFaceMedium: $font-face-medium;
    fontFaceMediumItalic: $font-face-mediumItalic;
    fontFaceBold: $font-face-bold;
    fontFaceBoldItalic: $font-face-boldItalic;
    fontFaceLight: $font-face-light;
    fontFaceLigthItalic: $font-face-ligthItalic;
}