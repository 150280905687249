.mhc-navbar {
  width: 100%;
  display: inline-flex;
  margin: 0rem 1rem;
  justify-content: space-between;
  align-items: center;

  .brand-wrapper {
    display: inline-flex;
    align-items: center;

    .brand-logo {
      display: flex;
      align-items: center;
      // padding-left: 1.5rem;
      cursor: pointer;
    }

    .brand-name {
       padding-left: 1.25rem;
      font-family: $font-face-medium;
      font-style: normal;
      font-weight: $font-weight-heavy;
      font-size: 1.44rem;
      line-height: 30px;

      /* Brand / Primary */

      color: $primary;
      cursor: pointer;
    }
  }

  .misc-wrapper {
    min-width: 7rem;
    //display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      cursor: pointer;
      &:first-child {
        margin-right: 2.625rem;
      }

      &:last-child {
        border: 1px solid #b6b6b6;
        border-radius: 50%;
        background-color: #b6b6b6;
        color: #fff !important;
        padding: 10px;
      }
    }
  }
}
