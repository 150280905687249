@mixin for_phone {
	@media (max-width: 768px) {
		@content;
	}
}
.login-page {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  @include for_phone() {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }

  .brand-section {
    display: flex;
    align-items: center;
    @include for_phone() {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
  }

  .login-page-logo {
    width: 138px;
    height: 138px;
  }

  .brand-description {
    padding-left: 1.875rem;
  }

  .brand-name {
    font-family: $primary-font;
    font-style: normal;
    font-weight: $font-weight-heavy;
    font-size: $font-size-massivePlus;
    line-height: 55px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.002em;

    /* Brand / Primary */

    color: #594a9e;
  }

  .app-platform {
    font-family: $primary-font;
    font-style: normal;
    font-weight: $font-weight-lightHeavy;
    font-size: $font-size-largePlus;
    line-height: 32px;
    /* identical to box height, or 116% */

    display: flex;
    align-items: center;
    letter-spacing: -0.005em;

    /* Brand / Primary */

    color: $primary;
  }

  .login-form-container {
    padding: 2rem;
  }

  .login-title {
    padding-bottom: 0.5rem;
    .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: $font-weight-heavy;
      font-size: $font-size-massivePlus;
      line-height: 55px;
      /* identical to box height */

      display: flex;
      align-items: center;
      letter-spacing: -0.002em;

      /* Brand / Primary */

      color: $primary;
      // color: #535adf;
    }
  }

  .password-field {
    display: flex;
    position: relative;
    align-items: center;

    i {
      position: absolute;
      right: 2rem;
      cursor: pointer;
      color: $lightGrey;
    }
  }

  .keep-logged-in {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    input {
      cursor: pointer;
    }

    .keep-logged-in-text {
      font-family: $primary-font;
      font-style: normal;
      font-weight: $font-weight-light;
      font-size: $font-size-mediumPlus;
      line-height: 24px;
      /* identical to box height, or 133% */

      /* Grayscale / Black */

      color: $grayscale-black;

      /* Inside auto layout */

      //flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 8px;
    }
  }

  .login-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;

    /* Brand / Secondary */

    background: $secondary;
    border-radius: 24px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px 22px;
    width: 100%;
  }

  .forgot-password {
    font-family: $primary-font;
    font-style: normal;
    font-weight: $font-weight-heavy;
    font-size: $font-size-mediumPlus;
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;
    letter-spacing: 0.02em;

    /* Brand / Secondary */

    color: $secondary;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;

    cursor: pointer;
  }
}

.form-section.reset-section {
  width: 360px;
  height: 326px;

  .MuiPaper-root {
    margin-top: 0 !important;
  }

  .reset-section-label {
    margin-bottom: 1rem;
  }
  .title {
    font-size: $font-size-mediumPlus;
  }

  .login-submit {
    margin-top: 2rem;
    color: $primary;
    border: 0;
  }
}
