@import "../../../assets/style/variables/index";

.navbar-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  background-color: $primary;
  align-items: center;

  .container-section1 {
    color: aliceblue;
    .credentials:nth-child(1) {
      font-family: Nunito-Regular;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
    .credentials:nth-child(2) {
      font-family: Nunito-Regular;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .container-section2 {
    margin-left: 1rem;
  }
}
