
.edit-typo {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 145%;
  letter-spacing: 0.01em;
  color: #fdba12;
}

.leftmar{
  margin-left: 10px;
}