@import "../../assets/style/variables/index";

.page {
  overflow-x: hidden;
}
.head-section-image {
  max-width: 32px;
  max-height: 32px;
}
.referral_points_display {
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  h5:nth-child(1) {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
  }
  h5:nth-child(2) {
    color: $primary;
    margin-top: 3rem;
  }
}

.employee-button-review {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 10px 83px; */
  justify-content: center;
  min-width: 485px;
  height: 50px;
  border: 0;
  background: #fdba12;
  border-radius: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--primary-color, #594a9e);
}

.retake-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 10px 24px; */
  justify-content: center;
  width: 485px;
  height: 50px;
  border: 2px solid #fdba12;
  box-sizing: border-box;
  border-radius: 24px;
  background: #fafafa;
  color: #fdba12;
}

.reviewview-section_1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .frame2 {
    margin-bottom: 0.8rem;
    input {
      width: 150%;
      height: 77px;
    }

    flex-direction: column;
    &_text {
      color: $primary;
    }
    &_text1 {
      color: #878787;
      cursor: pointer;
    }
  }
}
.reviewview_headSection_points {
  text-align: center;
}
.reviewview-sections {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;

  .frame1 {
    margin-top: 20px;
    height: 150px;
    flex: 1;
    max-width: 300px;
    padding: 0rem 1rem;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    margin-right: 1rem;
    min-width: fit-content;
    &:last-child {
      margin-right: 0;
    }

    & > * {
      margin-right: 1rem;
    }

    .frame1-head-section-image {
      display: flex;
      justify-content: center;
      max-height: 60%;
    }
    .flexitem-row {
      vertical-align: middle;

      // .flexitem-row:nth-child(1) {
      //     margin-left: 10px;
      //   }
    }
    .flexitem-row:nth-child(1) {
      margin-left: 18px;
    }
    .flexitem-row:nth-child(3) {
      margin-right: 18px;
    }
  }
}

.reviewview_headSection_overallValues {
  text-align: center;
  display: flex;
  justify-content: space-around;
}
.reviewview_headSection_img_name_date {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}
.review-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  justify-content: center;
  position: static;
  font-family: Nunito-Regular;
  font-style: normal;
  width: 60px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fafafa;
}
.review-warning {
  padding: 4px 12px;
  background: $secondary;
  border-radius: 25px;
}
.review-error {
  padding: 4px 12px;
  background: $error;
  border-radius: 25px;
}
.review-no {
  padding: 4px 36px;

  // border-radius: 25px;
  color: black;
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .reviewview_headSection {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    flex-direction: row !important;
    align-items: center;
    // max-width: 100%;
    .reviewview_headSection_img_name_date {
      display: flex;
      flex-direction: row !important;
      gap: 0.75rem;
      align-items: center;
      img {
        margin: 10px 10px;
        margin-right: 0rem !important;
      }
      h5 {
        margin-top: 8px;
      }
    }
    .reviewview_headSection_overallValues {
      display: flex;
      flex-direction: row !important;
      gap: 0.5rem;
      align-items: center;
      h6 {
        margin-top: 10px;
      }
    }
  }
  .frame2_section2 {
    display: flex;
    justify-content: space-between;
    .frame2:nth-child(1) {
      margin-right: 10px;
    }
    .frame2:nth-child(2) {
      margin-right: 20px;
    }
    button {
      margin-top: 28px;
    }
  }

  .reviewview-section_1 {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    // .frame2{
    //     display: flex;
    //     flex-direction: column;
    //     input{
    //         width:140%;
    //     }
    //     &_text1{
    //         color:#878787;
    //         cursor: pointer;
    //         margin-left: 20px;
    //         margin-top: 30px;
    //      }
    // }
  }

  .reviewview-sections {
    display: flex;
    justify-content: space-between;

    .frame1 {
      height: 100px;
      margin-bottom: 20px;

      .flexitem-row {
        vertical-align: middle;

        // .flexitem-row:nth-child(1) {
        //     margin-left: 10px;
        //   }
      }
      .flexitem-row:nth-child(1) {
        margin-left: 10px;
      }
      .flexitem-row:nth-child(3) {
        margin-right: 10px;
      }
    }
  }
  .reviewview-sections .frame1 .frame1-head-section-image {
    max-height: 48px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .reviewview_headSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .reviewview_headSection_img_name_date {
      display: flex;
      flex-direction: row;
      flex-basis: 62%;
      margin-top: 10px;
      justify-content: space-between;
      margin-left: -0.8rem;

      h5 {
        margin-top: 7px;
      }
      img {
        padding: 0.5rem;
      }
    }
    .reviewview_headSection_overallValues {
      display: contents;
      flex-direction: row;
      flex-basis: 30%;
      // margin-top: 8px;
    }
  }

  .reviewview-section_1 {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
    margin-left: 0%;
    text-align: center;
  }
  .frame2_section2 {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
      div:nth-child(3) {
        margin-bottom: 20px;
        margin-left: 40%;
      }
    }
  }
  textarea.form-control {
    width: 350px;
    margin-left: 20%;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1440px) {
  .reviewview_headSection {
    display: flex;
    flex-direction: row;
    margin: 20px 10px 10px;
    justify-content: space-between;

    .reviewview_headSection_img_name_date {
      display: flex;
      flex-direction: row;
      flex-basis: 30%;
      justify-content: space-around;
      margin-left: -0.8rem;
      img {
        padding: 10px;
        padding-left: 0px;
      }

      h3 {
        margin-top: 4px;
      }
      h5 {
        margin-top: 12px;
      }
    }
    .reviewview_headSection_overallValues {
      display: flex;
      flex-basis: 65%;
      flex-direction: row;
      justify-content: flex-end !important;
      column-gap: 40px;
    }
  }
  .reviewview-section_1 {
    margin-top: 20px;
  }
  .frame2_section2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 2rem;
    gap: 2rem;
    button {
      margin-top: 24px;
    }
    .row {
      display: flex;
      flex-direction: row;
      column-gap: 30px;
      div:nth-child(3) {
        margin-bottom: 20px;
      }
    }
  }
  textarea.form-control {
    width: 350px;
    height: 120px !important;
  }
  .reviewview-section_1 .frame2_text1 {
    margin-top: 32px;
  }
  .reviewview-sections .frame1 .frame1-head-section-image {
    max-width: 80px;
    max-height: 80px;
  }
}

.history-log {
  display: flex;
  img {
    margin-right: 1rem;
  }
}

.selections {
  padding: 1rem;

  .error-message {
    font-size: 0.8rem;
    color: $red;
  }

  .reason-label {
    font-weight: bold;
    margin-bottom: 1rem;
    background-color: $primary;
    color: #fafafa;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    padding: 5px 5px;
  }

  .form-group {
    margin-bottom: 1rem;
    margin-top: 0;
    label {
      margin-bottom: 1rem;
    }
  }

  .custom-select {
    &-items {
      padding: 1rem 0;
      display: flex;
      flex-wrap: wrap;
    }

    &-items button {
      outline: none;
      border: none;
      padding: 0.2rem 0.5rem;
      margin-right: 0.8rem;
      border-radius: 2px;
    }
  }

  .custom-radio {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &.single {
      grid-template-columns: 1fr;
    }
    &-items {
      margin-bottom: 1rem;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      & > * {
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
          white-space: break-spaces;
          margin-top: -5px;
        }
      }
    }
  }
}
