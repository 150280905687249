@import "/src/assets/style/variables/typography";
@import "/src/assets/style/variables/index";
@import "/src/assets/style/variables/colors";

.outer-layer {
  width: 100%;
  // min-height: 100vh;
  padding: 4rem 0.5rem 3rem;
  max-height: 100vh;
  overflow: auto;
  background-color: #efefef;
  padding: 1rem;
}
.out-layout {
  display: flex;
  flex-direction: column;
  //align-items: center;
  padding: 36px;
  background-color: #fafafa;
  margin: 1px;
  border-radius: 4px;
}

.typo-disc {
  font-family: $primary-font;
  font-size: $font-size-medium;
  font-weight: $font-weight-light;
  line-height: 22px;
  letter-spacing: 0em;
  color: $grayscale-black;
}

.btn-sve {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 24px;

  width: 90px;
  height: 44px;
  color: $brand;

  /* Brand / Secondary */

  background: #fdba12;
  border-radius: 24px;
  border: 0px;
  /* Inside auto layout */

  //styleName: Desktop / Button;

  font-size: $font-size-mediumPlus;
  font-weight: $font-weight-heavy;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.seprator {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top-txt {
  padding-left: 1rem;
}

.MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}

.marrginbtntbl {
  margin-bottom: 1.5rem;
}

.MuiFormGroup-root {
  margin-left: 47px;
  .MuiFormControlLabel-label {
    margin-left: 1rem;
  }
}

// .MuiTypography-root MuiFormControlLabel-label MuiTypography-body1{
//   margin-left: 2rem;
// }
.position {
  display: flex;
  flex-direction: column;
}
