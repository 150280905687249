@use "assets/style/variables/_index.scss" as vars;

.displaynamedate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1.4rem;
}
.reviewviewview_display_texts {
  // max-height: 200px;
  // overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, max-content);
  gap: 1.5rem;
  row-gap: 2rem;
  .comment {
    border: 0.5px solid #b6b6b6;
    border-radius: 8px;
    padding: 1rem;
    min-height: 80px;
  }
}
.review_screen_container {
  //   overflow-y: hidden;
  display: grid;

  height: 100%;
  min-height: 100vh;
  grid-template-columns: 1fr;

  @include vars.md-screen {
    grid-template-columns: 3fr 1fr;
  }
}
.review_screen_form {
  display: none;
  background-color: #ffffff;
  padding: 1rem 2rem;
  overflow-y: auto;

  &.review_screen_form_active.modal {
    display: block;
    height: 100%;
  }

  @include vars.md-screen {
    display: block;
  }
}

.review_screen_form_modal {
  width: 80vw;
  height: 100%;
  overflow: auto;
  @include vars.responsive(500px) {
    max-width: 400px;
    & > div {
      padding: 1rem;
    }
  }
}

.review_screen_image_container {
  background: #000;
  display: grid;
  grid-template-rows: max-content auto 2rem;

  .review_screen_close {
    padding: 0.5rem 1rem;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    @include vars.sm-screen {
      .review_screen_face_icon {
        display: none;
      }
    }
    @include vars.md-screen {
      .review_screen_more_icon {
        display: none;
      }
    }
  }
}
.review_screen_views_container {
  display: grid;
  grid-template-columns: 1fr;

  @include vars.sm-screen {
    grid-template-columns: 1fr max-content;
  }
}

.review_screen_image {
  position: relative;
  padding: 1rem;
}

.review_screen_tools_container {
  display: none;
  // align-items: end;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  z-index: 101;

  &.review_screen_tools_container_active {
    display: grid;
    height: 80vh;
  }

  @include vars.sm-screen {
    display: grid;
    position: static;
    transform: unset;
    padding: 1rem;
  }
}

.review_screen_tools {
  // min-height: 200px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 15% max-content auto;
  gap: 10px;

  & > div {
    background: #2f2f3c;
    border-radius: 0.5rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .review_screen_grid_toggle {
    background: none;
    display: grid;
    place-items: center;
    height: max-content;

    $border-width: 2.5px;

    &.review_screen_grid_v_toggle.active img {
      border: $border-width solid #82c91e;
    }

    &.review_screen_grid_h_toggle.active img {
      border: $border-width solid #6db0e7;
    }

    img {
      $image-dimensions: 55px;
      width: $image-dimensions;
      height: $image-dimensions;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .review_screen_head_carousel {
    grid-column: 1 / span 2;
    padding-top: 0.5rem;
  }
  .review_screen_slider {
    background: #2f2f3c;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 30px auto 30px;
    row-gap: 0.8rem;

    color: #fff;
    height: 100%;
    padding: 0.8rem 0;

    &_container {
      background: none;
      height: 100%;
      width: 100%;
      display: grid;
      place-items: center;
      background: none;
      outline: none;
      border: none;
    }
  }

  .review_screen_handles {
    color: white;
    background: transparent;
    outline: none;
    border: none;
  }
}

.review_screen_head_carousel {
  display: grid;
  column-gap: 0.5rem;
  justify-items: center;
  align-items: center;

  grid-template-areas: "left head right";
  grid-template-columns: 1fr 2fr 1fr;

  .review_screen_head_image {
    grid-area: head;
    padding-top: 0.8rem;

    img {
      height: 60px;
      width: auto;
    }

    @include vars.sm-screen {
      padding: 0;
    }
  }
  .review_screen_head_carousel_arrow {
    color: #fff;
    svg {
      font-size: 2rem;
    }
    &:first-child {
      grid-area: left;
    }
    &:last-child {
      grid-area: right;
    }
  }
  .review_screen_head_carousel_edge {
    color: #585862;
  }
}

.review_screen_canvas {
  height: 100%;
  // aspect-ratio: 60 / 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  pointer-events: none;
  display: grid;
  place-items: center;
}
.review_screen_form_text_area {
  margin: 0 !important;
  width: 100% !important;
}
