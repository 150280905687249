.drawer-title {
    font-family: $primary-font;
    font-style: normal;
    font-weight: $font-weight-lightHeavy;
    font-size: $font-size-largePlus;
    line-height: 120%;
    /* or 37px */

    display: flex;
    align-items: center;
    letter-spacing: -0.005em;

    /* Grayscale / Gray 4 */

    color: $gray4;

    background: $grayscale-white;
    border-bottom: 1px solid $lightGrey;

    span {
        padding-left: 16px;
    }

    i {
        cursor: pointer;
        padding-right: 16px;
    }
}

.drawer-body {
    height: calc(100vh - 208px);
    overflow: auto;
    padding: 16px 8px;
}

.drawer-footer {
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    padding-right: 8px;
    justify-content: flex-end;
    align-items: center;
}