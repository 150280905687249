.mhc-confirm-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999 !important;

  .mhc-confirm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;

    width: 449px;
    min-height: 206px;

    /* Grayscale / White */

    background: $grayscale-white;
    /* Card Shadow */

    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .confirm-title {
    /* Desktop / H6 */

    font-family: $primary-font;
    font-style: normal;
    font-weight: $font-weight-heavy;
    font-size: $font-size-large;
    line-height: 130%;
    /* identical to box height, or 28px */

    letter-spacing: 0.005em;

    /* Brand / Primary */

    color: $primary;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0 0 16px;
  }
  .confirm-body {
    /* Desktop / Paragraph */

    font-family: $primary-font;
    font-style: normal;
    font-weight: $font-weight-light;
    font-size: $font-size-mediumPlus;
    line-height: 150%;
    /* or 27px */

    letter-spacing: 0.01em;

    /* Grayscale / Black */

    color: $grayscale-black;

    /* Inside auto layout */

    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px 16px;
  }

  .confirm-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1rem;

    .confirm-no {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px;
      font-weight: bold;

      /* Brand / Secondary */

      background: transparent;
      border-radius: 24px;
      border: 0.5px solid $secondary;
      color: $secondary;

      box-shadow: 0px 3px 0 0 $secondary;
      &:active {
        box-shadow: none;
        transform: translateY(3px);
      }

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 0px 0px 16px;
    }

    .confirm-yes {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 28px;

      font-weight: bold;

      /* Brand / Secondary */

      background: $secondary;
      border-radius: 24px;
      //   border-color: $secondary;

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 16px;
      border: none;
      box-shadow: 0px 3px 0 0 $primary;
      &:active {
        box-shadow: none;
        transform: translateY(3px);
      }

      color: $primary;
    }
  }
}
