@import "./../../../assets/style/style.module.scss";

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner {
  padding: 2rem;
  background-color: #fff;

  .title-container {
    @include flexCenter;

    .title-text {
      font-size: 1.5rem;
      color: $primary;
      font-weight: bold;
      flex: 1;
      text-align: center;
    }

    .back-icon {
      justify-self: flex-start;
    }
  }
  .stepper-container {
    margin-top: 1rem;

    .step-label-container {
      @include flexCenter;
      margin-bottom: 1rem;
    }
  }

  .half {
    width: 50%;
  }
  .button-group {
    display: grid;
    margin-top: 1rem;
    grid-template-columns: 100px 100px auto 100px;
    grid-template-areas: "cancel prev . submit";
    column-gap: 1rem;
    button {
      margin: unset;
      justify-content: center;
    }
    .cancel-btn {
      grid-area: cancel;
    }

    .prev-btn {
      grid-area: prev;
    }
    .save-btn {
      grid-area: submit;
    }
  }
}
