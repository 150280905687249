@import "../../assets/style/variables/index";
.Page {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding: 2rem;
}
.brand_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .brand_logo {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .brand_name {
    padding-left: 1.25rem;
    font-family: $font-face-medium;
    font-style: normal;
    font-weight: $font-weight-heavy;
    font-size: 1.44rem;
    line-height: 30px;

    /* Brand / Primary */

    color: $primary;
    cursor: pointer;
  }
}

.txt_gap {
  margin: 1rem 0;
}

.checkbox_label {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.ckt_btn {
  background: #fdba12;
  border-radius: 24px;
}

.input_field {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
}
.txt_success {
  color: green;
  margin-top: 0.3rem;
}
.txt_fail {
  color: red;
  margin-top: 0.3rem;
}
.heading {
  font-weight: bold;
  margin-top: 0.5rem;
}
