@import "../../../assets/style/style.module.scss";

$red: #dd7070;
$yellow: #ffd046;
$green: #7fb77e;
$blue: #7e73b1;
$orange: #faa96e;

.patient-list-layout {
  //   display: flex;
  margin-left: 2%;
  margin-right: 2%;
  .patient-list-header {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: baseline;
    .patientName {
      font-family: Nunito;
      font-size: 31px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: left;
    }
    .dateAndSearch {
      .search {
        display: flex;
        align-items: center;
      }
    }
  }
}
.status_green {
  padding: 4px 12px;
  background: $green;
  border-radius: 4px;
  border-color: $green;
  color: #fff;
  font-family: $primary-font;
  font-style: normal;
}
.status_red {
  padding: 4px 12px;
  background: $red;
  border-radius: 4px;
  border-color: $red;
  color: #fff;
  font-family: $primary-font;
  font-style: normal;
}
.status_yellow {
  padding: 4px 12px;
  background: $yellow;
  border-radius: 4px;
  border-color: $yellow;
  color: #fff;
  font-family: $primary-font;
  font-style: normal;
}
.status_blue {
  padding: 4px 12px;
  background: $blue;
  border-radius: 4px;
  border-color: $blue;
  color: #fff;
  font-family: $primary-font;
  font-style: normal;
}
.status_none {
  padding: 4px 12px;
  background: $lightGrey;
  border-radius: 4px;
  border-color: $lightGrey;
  color: #fff;
  font-family: $primary-font;
  font-style: normal;
}
.Report-Button {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 16px;

  background: $secondary;
  border-radius: 24px;
  border: 0px;

  //styleName: Desktop / Button;
  font-family: $primary-font;
  font-size: 1rem;
  font-weight: $font-weight-heavy;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: $primary;
  margin-top: 1rem;
}
.align-left {
  th,
  td {
    text-align: left !important;
    border-bottom: 0.5px solid $primary !important;
  }
  th {
    background-color: $primary;
    color: #fff !important;
  }
}

.patient-list.badge-primary {
  background-color: $blue !important;
  border-color: $blue;
}

.patient-list.badge-warning-status {
  padding: 4px 12px;
  background: $yellow;
  border-radius: 4px;
}

.patient-list.badge-success-status {
  padding: 4px 12px;
  background: $green;
  border-radius: 4px;
}

.patient-list.badge-error-status {
  padding: 4px 12px;
  background: $red;
  border-radius: 4px;
}
.badge-error-unreview {
  padding: 4px 12px;
  background: $orange;
  border-color: $orange;
  color: #fafafa;
  border-radius: 4px;
}
.patient-list.red-badge {
  margin-top: 2rem;
  color: $error;
}
.patient-list.yellow-badge {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: $status-warning-base;
}
.patient-list.green-badge {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: $status-success-base;
}

.filter {
  flex-direction: column;
  position: absolute;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid var(--primary-color);
  right: 0;
  top: 35px;
  z-index: 9999;
  button {
    margin-bottom: 0.8rem;
    &:last-child {
      margin-bottom: 0rem;
    }
  }
}
