//color palatte

$blue: #0394c7;
$orange_dark: #d9480f, 90%;
$orange: #f76707;
$brand: #594a9e;
// $primary: #594A9E;
$primary: var(--primary-color, #594a9e);
$primaryLight: #6554b2;
$grayscale-white: #fafafa;

//new color palatte
// $brand-emp-base: #0394c7;
// $brand-emp-light: #81cae3;
// $brand-emp-dark: #024869;
// $brand-co-base: #f76707;
// $brand-co-light: rgba(217, 72, 15, 0.9);
// $brand-co-dark: #d9480f;

// $black: #212529;
// $gray8: #343a40;
$gray7: #495057;
// $gray6: #868e96;
$gray5: #adb5bd;
$gray4: #495057;
$gray3: #dee2e6;
$gray2: #e9ecef;
// $gray1: #f1f3f5;
// $gray0: #f8f9fa;
// $white: #ffffff;

$grayscale-black: #2f2f3c;

$status-info-base: #4263eb;
$status-info-light: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #4263eb;
$status-info-lighter: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4263eb;
$status-error-base: #f03738;
$status-error-light: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #f03738;
$status-error-lighter: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f03738;
$status-warning-base: #f59f00;
$status-warning-light: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #f59f00;
$status-warning-lighter: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f59f00;
$status-success-base: #3cc13b;
$status-success-light: linear-gradint(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #3cc13b;
$status-success-lighter: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3cc13b;

$table-emp-row-hover: rgba(3, 148, 199, 0.2);
$table-co-row-hover: rgba(219, 88, 37, 0.2);
$table-emp-row-selected: rgba(3, 148, 199, 0.4);
$table-co-row-selected: rgba(219, 88, 37, 0.4);
//$tableCoRowHover: $table-co-row-hover;

$white: #ffffff;
$paleWhite: #fafafa;
$purple: #594a9e;
$lightPurple: #6554b2;
$darkPurple: #483c80;
$yellow: #fdba12;
$black: #2f2f3c;
$pureBlack: #000;
$grey: #878787;
$lightGrey: #b6b6b6;
$lighterGrey: #efefef;
$green: #4a9e52;
$red: #df5647;
$paleRed: #fffbfa;
$palePurple: #e7dff7;
$orange: #f2994a;
$facebook: #1977f3;
$google: #fafafa;
$bronze: #a97142;
$silver: #c5ced4;
$gold: #ffd700;
$secondary: $yellow;
$tableBorder: #d0d0d0;
$warning: #f39c12;
$badgeCa: #74b9ff;
$success: #34c272;
$error: $red;

:export {
  //color palatte
  blue: $blue;
  orange_dark: $orange_dark;
  orange: $orange;
  brand: $brand;
  primary: $primary;

  white: $white;
  paleWhite: $paleWhite;
  purple: $purple;
  lightPurple: $lightPurple;
  darkPurple: $darkPurple;
  yellow: $yellow;
  black: $black;
  pureBlack: $pureBlack;
  grey: $grey;
  lightGrey: $lightGrey;
  lighterGrey: $lightGrey;
  green: $green;
  red: $red;
  paleRed: $paleRed;
  palePurple: $palePurple;
  orange: $orange;
  facebook: $facebook;
  google: $google;
  bronze: $bronze;
  silver: $silver;
  gold: $gold;
  secondary: $secondary;
  tableBorder: $tableBorder;
  warning: $warning;
  badheCa: $badgeCa;
  success: $success;
  error: $red;
}
