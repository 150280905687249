$sidebar_background: $lighterGrey;
$arrow_expanded_color: $primary;

// .MuiPaper-root{
//  // margin-top: 10vh;
// }
.sidebar-item__content {
  &:hover {
    .sidebar-item__label.parent {
      .MuiTypography-displayBlock {
        color: $arrow_expanded_color !important;
      }
    }
    i {
      color: $arrow_expanded_color !important;
    }
  }
}

// .MuiTypography-body1 {
//     font-family: Nunito-Regular !important;
// }
.sidebar {
  height: 80vh !important;
  border-bottom: none !important;
}

.toggle-icon {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  padding: 0.5rem;

  &[data-bg="collapsed"] {
    padding: 0 0.5rem;
    right: 18px;
  }
}

.sidebar-item {
  //box-shadow: 8px 0px 4px -3px rgba(0, 0, 0, 0.75);
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) !important;

  & > .sidebar-item--active {
    border-left: 4px solid $arrow_expanded_color;
  }
}

.sidebar-item--active {
  //background-color: $sidebar_background
  //!important;
  color: $arrow_expanded_color !important;

  i {
    color: $arrow_expanded_color !important;
  }

  .sidebar-item__label.parent {
    .MuiTypography-displayBlock {
      color: $arrow_expanded_color !important;
    }
  }
}

.sidebar-item {
  &__content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
  }

  &__icon {
    margin-right: 6px;
  }

  &__divider {
    margin: 16px auto !important;
    width: 95%;
    background-color: white !important;
  }

  &__arrow {
    font-size: 24px !important;
  }

  &__arrow--expanded {
    color: $arrow_expanded_color;
    font-weight: bold;
  }

  &__label:not(.parent) {
    // padding-left: .25rem;
    padding: 0rem 0.25rem;
    // height: 0.9rem;
    .MuiTypography-displayBlock {
      font-family: $primary-font;
      font-style: normal;
      font-weight: $font-weight-lightHeavy;
      font-size: $font-size-medium;
      line-height: 150%;
      /* identical to box height, or 27px */

      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
    }
  }

  &__label.parent {
    .MuiTypography-displayBlock {
      font-family: $primary-font;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-medium;
      line-height: 130%;
      /* identical to box height, or 28px */
      letter-spacing: 0.005em;

      /* Grayscale / Gray 3 */

      color: #878787;
    }
  }

  &:hover .sidebar-item__icon {
    background-color: $sidebar_background;
    font-weight: $font-weight-lightHeavy;
    color: $white;
  }
}
