.view-user {
  padding: 1rem 2rem;
  background: #fff;

  .form-group {
    display: flex;
    align-items: center;

    label {
      margin-right: 1rem;
      flex: 0 0 10%;
    }

    .cancel {
      margin-left: 1rem;
    }
  }
  .form-group-treatment {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 1rem;
  }
  .textarea-treatment {
    border: 1px solid;
    padding: 1rem;
    border-radius: 5px;
  }
}
