@import "../../../assets/style/variables/index";
.unity-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  // margin-bottom: 4rem;
  // display: flex;
  // flex-direction: row;
  // column-gap: 3.2rem;
  // justify-content: center;
  // /* justify-content: space-around; */
  // flex-wrap: wrap;
  margin-bottom: 29px;
}
.closeCommentModal {
  direction: rtl;
}
.container-position {
  display: flex;
  flex-direction: row;
  width: 95%; // width: 1563px;
  // margin-right: 0.5rem;
  justify-content: center;
}
.comment-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-score {
  display: grid;
  grid-template-columns: 2.5fr 1.5fr;
  grid-gap: 1rem;
  margin-top: 2.4rem;
}

.active-head {
  background-color: #fdd05d;
}

.icon-border {
  height: 80px;
  width: 80px;
  border-radius: 3px;
  border: 1px solid #2f2f3c42;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  padding: 1.8rem;
}

.container-main {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 0;

  font-family: $primary-font;
  font-size: $icon-font-size-smaller;
  font-weight: $font-weight-lightHeavy;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: $grayscale-black;
  margin-top: 1rem;
}

.imageContainer {
  // width: 500px;
  // height: 453px;
  // max-width: 100%;
  // height: auto;
  // // border: 1px solid red;
  // object-fit: cover;
  // object-position: right top;
  // position: relative;
  width: 500px;
  max-width: 100%;
  height: "auto";
  aspect-ratio: 9 / 16;
  object-fit: cover;
  object-position: right top;
  position: relative;
}

.imageBlur {
  position: relative;
}
.imageBlur:after {
  background: rgba(23, 23, 23, 0.574);
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  z-index: 1;
  left: 0;
}
.noImg {
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 60px;
  }
}
.imgContaineroutline {
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2.2rem 0;
  font-family: $primary-font;
  font-size: $icon-font-size-smaller;
  font-weight: $font-weight-lightHeavy;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: $grayscale-black;
  position: relative;
}

.shuffle {
  max-width: 40px;
  cursor: pointer;
  z-index: 5;
  margin-top: 1rem;
}

.phy-box {
  width: 100%;
  border: 1px solid #2f2f3c59;
  border-radius: 5px;
  max-height: 242px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  th,
  td {
    // border: 0;
    font-family: "Nunito-Regular", sans-serif;
  }
  // padding: 2rem;
  // margin-top: 4.56rem;
}

.gridContainer {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 363px 173px 170px;
  grid-gap: 8px;
  gap: 8px;
  padding: 1.5rem 4.5rem 2.5rem 4.5rem;
  font-size: 1rem;
}

.baseline {
  // flex: 1 1; new change
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  // max-width: 45%;
  max-width: 38%;

  &:last-of-type {
    margin-right: 0;
  }
  .second-img {
    .imgContaineroutline {
      justify-content: right;
    }
  }
}

.table-border {
  .tr,
  .td {
    border: none;
  }
}
.cropper-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.individual-cmt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.individual-txtarea {
  width: 100%;
}

.full-img-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  margin-top: -4rem;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .baseline {
    width: auto;
    .imageContainer {
      width: auto;
      height: auto;
      // border: 1px solid red;
    }
  }
  .gridContainer {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 156px 173px 170px;
    grid-gap: 8px;
    grid-gap: 1px;
    gap: 3px;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }

  .imgContaineroutline {
    width: 270px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .shuffle {
    width: 35px;
    margin-top: auto;
    cursor: pointer;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1290px) {
  .imageContainer {
    width: 450px;
    height: 453px;
    // border: 1px solid red;
  }
  .imgContaineroutline {
    width: 450px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .shuffle {
    cursor: pointer;
  }
}

//form
.input-text {
  // width: 285px;
  display: flex;
  padding: 1.55rem 0 0.8rem 0;
}
.icon-border {
  height: 60px;
  width: 60px;
  border-radius: 3px;
  border: 1px solid #2f2f3c42;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-text1 {
  // width: 285px;
  display: flex;
  padding: 1.55rem;
}

.buttons-phy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 61px;
  border: 0px;
  background: #fdba12;
  border-radius: 24px;
  margin-left: auto;
  color: $primary;
  font-family: Nunito-Regular;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
}

.align-btns {
  display: grid;
  justify-content: end;
  grid-template-columns: auto auto auto;
  // margin-top: 2.5rem;
  column-gap: 1rem;
}

.comment-ind-overall {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 230px;
  overflow-y: auto;
}

.phy-comment-normal {
  padding: 1rem;
  resize: vertical;
  width: 100%;
  background: #efefef;
  border: 1px solid rgba(135, 135, 135, 0.35);
  border-radius: 6px;

  font-size: 1rem;
}

.bamodelalign {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.phy-score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #2f2f3c59;
  // margin-top: 2.56rem;
  border-radius: 5px 5px 0px 0px;
}

.phy-activebox {
  background: #fdba12;
  border-radius: 5px 0px 0px 0px;
}

.overall-txt {
  width: 50%;
  padding: 7px 7px 7px 200px;
  border-radius: 5px 0px 0px 0px;
  color: $primary;
}

.overall-txt-op1 {
  width: 50%;
  text-align: center;
  padding: 7px 7px 7px;
  border-radius: 5px 0px 0px 0px;
  color: $primary;
}

.individual-txt {
  // background: #fdba12;
  width: 50%;
  padding: 7px 7px 7px 200px;
  border-radius: 5px 0px 0px 0px;
  color: $primary;
}
.individual-txt-op1 {
  width: 50%;
  padding: 7px 7px 7px 7px;
  text-align: center;
  display: flex;
  border-radius: 5px 0px 0px 0px;
  justify-content: center;
  color: var(--primary-color, #594a9e);
  align-items: center;
}
.individual-txt1 {
  // background: #fdba12;
  width: 100%;
  padding: 7px 7px 7px 16px;
  border-radius: 5px 0px 0px 0px;
  color: $primary;
  text-align: center;
}

.bamodelset {
  display: flex;
  flex: 1;
  // margin-top: 5rem;
  height: 100%;
  .closeModal {
    position: absolute;
    right: 1rem;
  }

  .bna_container {
    width: 80%;
    display: flex;
  }
  img {
    object-fit: contain;
    height: 100%;
    // width: auto;
  }

  .slider__container {
    text-align: center;
  }
}

.baunity-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // flex-wrap: wrap;
  overflow: scroll;
  gap: 0.256rem;
  flex: 1;
  margin-left: 2rem;
  background: white;
  padding: 0 2rem;
  align-items: center;
  .icon1 {
  }
}

.bna-container {
  flex: 1;
  // margin-right: 2rem;
  max-width: 90%;
  // margin-left: 2rem;
}

.comment-container {
  flex: 1;
}

.arrow {
  position: absolute;
  display: flex;
  place-items: center;
  left: 0;

  height: 100%;

  &.right {
    right: 0;
    left: unset;
  }
}
.overall-scores {
  flex: 1;
}
.overall-scores-container {
  display: flex;
  flex-direction: column;
  .phy-box {
    flex: 1;
  }
}

.bna_header {
  font-weight: bold;
  color: $primary;
  margin-bottom: 1rem;
  // width: var(--summary-table-width);
  // min-width: 90vw;
  text-align: left;
  font-size: 1.4rem;
  padding: 1rem;
}

@media only screen and (min-width: 1290px) and (max-width: 1440px) {
  .icon-border {
    height: 100%;
    width: 100%;
    max-height: 50px;
    max-width: 55px;
    border-radius: 3px;
    border: 1px solid #2f2f3c42;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
}
@media only screen and (min-width: 1920px) {
  .icon-border {
    // height: 80px;
    // width: 80px;
    border-radius: 3px;
    border: 1px solid #2f2f3c42;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bna-footer {
  display: flex;
  justify-content: space-between;
}

.image_session {
  display: flex;

  .active_session {
    position: relative;
    &::after {
      background: $primary;
      content: "";
      height: 5px;
      display: block;
      position: absolute;
      width: 80%;
      /* z-index: 20; */
      left: 50%;
      border-radius: 10px;
      transform: translate(-50%, 0);
    }
  }
}

.bna-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 5%;
  flex-wrap: wrap;

  align-items: center;
  margin-bottom: 1rem;

  .align-btns {
    flex: 1;
  }
}

.overall-scores {
  td,
  th {
    max-width: 20ch;
  }
}
