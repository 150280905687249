.input-group .input-group-append .input-group-text {
  background: inherit;
}

.table-responsive.custom {
  //position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  background: #ffffff;
  /* Gray/Gray-5 */
  border: 1px solid $tableBorder;
  //border-radius: 4px;
  font-family: $primary-font;
  font-style: normal;
  font-weight: $font-weight-light;
  font-size: $font-size-mediumPlus;
  line-height: 145%;
  width: 100%;
  overflow-x: hidden;
  color: $grayscale-black;

  .page-item.active .page-link {
    background: $primary;
    border-color: $primary;
    color: $grayscale-white;
  }
  table {
    margin-bottom: 0px;
    font-size: 0.875rem;
    width: 100%;

    th {
      border: 0;
      //display: flex;
      overflow: visible;
      cursor: pointer;
      align-content: flex-start;
      padding: 10px 16px;
      //background: #dee2e6;
      color: #ffffff;
      font-weight: $font-weight-lightHeavy;

      .sort-up,
      .sort-down {
        display: inline-flex;
        align-items: center;
        margin-left: 0.5rem;
      }
    }
    td {
      border: 0px;
      cursor: pointer;
      padding: 12px;
      text-overflow: ellipsis;
      overflow: hidden !important;
      white-space: nowrap;
      max-width: 200px;

      div[class*="col"] {
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis;
        div {
          overflow: hidden;
          white-space: nowrap !important;
          text-overflow: ellipsis;
          strong {
            overflow: hidden;
            white-space: nowrap !important;
            text-overflow: ellipsis;
          }
        }
      }

      span.badge {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;
        justify-content: center;
        // width: 5rem;
        // font-size: 14px;

        position: static;
        font-family: $primary-font;
        font-style: normal;
        font-weight: $font-weight-lightHeavy;
        font-size: $font-size-small;
        line-height: 150%;
        /* identical to box height, or 21px */

        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Grayscale / White */

        color: $paleWhite;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;

        &.badge-error {
          padding: 4px 12px;
          background: $error;
          border-radius: 4px;
        }
        &.badge-success {
          padding: 4px 12px;
          background: $success;
          border-radius: 4px;
        }

        &.badge-ap {
          background: $status-warning-lighter;
          border-radius: 4px;
          border: 1px solid $status-warning-base;
          color: $status-warning-base;
          float: left;
        }
        &.badge-sc {
          background: $status-success-lighter;
          border-radius: 4px;
          border: 1px solid $status-success-base;
          color: $status-success-base;
          float: left;
        }

        &.badge-primary {
          padding: 4px 12px;
          background: $primary;
          border-radius: 4px;
        }
        &.badge-disabled {
          padding: 4px 12px;
          background: $gray5;
          border-radius: 4px;
        }

        &.badge-warning {
          padding: 4px 12px;
          background: $warning;
          border-radius: 4px;
        }

        &.badge-ca {
          padding: 4px 12px;
          background: $badgeCa;
          border-radius: 4px;
        }
        &.badge-silver {
          padding: 4px 12px;
          background: #c0c0c0;
          border-radius: 4px;
        }
      }
      select.borderless {
        border: none;
        background-color: inherit;
      }
    }

    tbody {
      tr {
        &:hover {
          &[data-view="Employee"] {
            background-color: $table-emp-row-hover;
          }
          &[data-view="CO"] {
            background-color: $table-co-row-hover;
          }
        }
        &.is-active {
          &[data-view="Employee"] {
            background-color: $table-emp-row-selected;
          }
          &[data-view="CO"] {
            background-color: $table-co-row-selected;
          }
        }
      }
      td {
        padding: 10px 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .form-check {
      text-align: center;
      .form-check-input {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    input[type="checkbox"] {
      position: relative;
      width: 1em;
      height: 1em;
      color: black;
      border: 1px solid $tableBorder;
      border-radius: 2px;
      appearance: none;
      outline: 0;
      cursor: pointer;
      transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
      &::before {
        position: absolute;
        content: "";
        display: block;
        top: 5px;
        left: 8px;
        width: 5px;
        height: 10px;
        border-style: solid;
        border-color: white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
      }
      &:checked {
        color: $primary;
        border-color: $primary;
        background: $primary;
        &::before {
          opacity: 1;
        }
        ~ label::before {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }

  //Commpact list start
  &.compact {
    display: flex;
    align-items: stretch;
    font-size: 0.875rem;
    width: 100%;
    overflow-x: hidden;
    // height: 100%;
    // overflow-y: auto;
  }
}

.paginate {
  display: block;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  padding: 12px;
  overflow-y: auto;

  label {
    margin: 0;
  }
  .pagination {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    .fa {
      font-size: 24px;
    }
  }
  select.borderless {
    border: none;
    background-color: inherit;
  }
  ul li.page-item {
    position: inherit;
    display: flex;
    margin: 3px;
    border-radius: 4px;
    font-style: normal;
    font-weight: $font-weight-light;
    font-size: $font-size-medium;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    justify-content: center;
    .page-link {
      font-size: 16px;
      color: $gray4;
      background: $white;
      border-radius: 4px;
      width: 36px;
      height: 36px;
      padding: 3px 3px;
      text-align: center;
      cursor: pointer;
    }
    &.active {
      .page-link {
        z-index: 0;
        color: #3e66fb;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3e66fb;
        border: 1px solid #3e66fb;
      }
    }
  }
}

.scrollable-table-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 250px);
  overflow-x: auto;
  overflow-y: auto;
}

.scrollable-table-container-lg {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  // min-height: 150px;
  min-height: 275px;
  overflow-x: auto;
  //overflow-y: auto;
}

.scrollable-table-container-compact {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 250px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
thead tr {
  position: sticky;
  top: 0;
  // display: inline-block;
  //background: #dee2e6;
  // background-color: rgba(89, 74, 158, 0.5);
  background-color: $primary;
}

.MuiPaper-root {
  ul {
    li {
      font-size: 14px;
    }
  }
}

.table-with-loader {
  position: relative;
  // pointer-events: none;
  .goc-loader-flexbox {
    position: absolute;
    left: 45%;
    top: 10%;
    div {
      margin-top: 0;
    }
  }
}
.empty-table-with-loader {
  position: relative;
  .goc-loader-flexbox {
    position: absolute;
    left: 45%;
    top: 0%;
    div {
      margin-top: 0;
    }
  }
}
// .table-with-loader.loading {
//   position: relative;
//   pointer-events: none;
//   .mhc-loader-flexbox {
//     position: absolute;
//     left: 45%;
//     top: 50%;
//     div {
//       margin-top: 0;
//     }
//   }
// }

.scrollable-table-container-lg,
.scrollable-table-container-compact {
  position: relative;
  .mhc-loader-flexbox {
    position: absolute;
    left: 45%;
    top: 45%;
    div {
      margin-top: 0;
    }
  }
}

.displayed-page-text {
  font-family: $primary-font;
  font-style: normal;
  font-weight: $font-weight-lightHeavy;
  font-size: $font-size-mediumPlus;
  line-height: 150%;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  /* Grayscale / Gray 3 */

  color: $grey;
}

.paginate-entries {
  font-family: $primary-font;
  font-style: normal;
  font-weight: $font-weight-lightHeavy;
  font-size: $font-size-mediumPlus;
  line-height: 150%;
  color: $grey;

  select {
    background: $grayscale-white;
    /* Grayscale / Gray 2 */

    border: 1px solid $lightGrey;
    box-sizing: border-box;
    border-radius: 4px;
    color: $grey;

    option {
      font-weight: $font-weight-light;
      line-height: 24px;
      color: $grey !important;
    }
  }
}
