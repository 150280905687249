@import "./_colors.scss";
@import "./_typography.scss";

@mixin responsive($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin sm-screen {
  @include responsive(700px) {
    @content;
  }
}

@mixin md-screen {
  @include responsive(1024px) {
    @content;
  }
}
