.show-more {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &.more {
    display: block;
  }

  &.less {
    display: -webkit-box;
  }
}
