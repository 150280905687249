@import "../../../assets/style/style.module.scss";

.pdf-container {
  width: 100%;
  height: 100%;
  background: #353535;
  display: flex;
  flex-direction: column;
}

.pdf-content {
  & * {
    font-size: unset;
  }

  font-size: 16px;

  .report__layout__patient__details {
    font-size: unset;
    grid-row-gap: unset;
    row-gap: unset;
    margin: 0.5rem 0rem;
  }
  .report__layout__comments {
    font-size: unset;
    margin: unset;
    word-wrap: unset;
  }
  .comments {
    grid-template-columns: 1fr;
    h4 {
      font-size: unset;
    }
  }
  .report__layout__header_container .date {
    font-size: 0.8rem !important;
  }
  .report__layout__header {
    margin-bottom: 0.5rem;
  }

  .pdf-table {
    width: 100%;
    .table_cell {
      padding: 0.25rem 0.5rem;
    }
    // border-bottom: 0.5px solid #d3d3d3;
    .table_header {
      background-color: $primary;
      color: white;
    }
  }

  .page {
    background-color: #fff;
    margin-bottom: 1rem;
  }
}

.comment-formatter {
  height: 150px;
  overflow: auto;
}

#root {
  height: 100vh;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  padding: 0.3rem 1rem;
}

.pdf-content-container {
  flex: 1;
  background: #353535;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-direction: column;

  .pdf-content {
    flex-direction: column;
    .content {
      padding: 1rem;
    }
  }
}

button.save-to-pdf {
  background: $secondary;
  color: #000;
  position: fixed;
  bottom: 20px;
  right: 5%;
  z-index: 9999;
  color: $primary;
  border-radius: 25px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
}

.pdf-header {
  // background-color: $primary;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  //   text-align: center;
  .logo {
    height: 50px;
    object-fit: contain;
  }
}

// pdf bna section

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

.bna-data {
  @include flexColumn;
  .content {
    flex: 1;
    @include flexColumn;
    padding: 1rem 2rem !important;
  }
}

.section_head {
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
}

.bna-image-container {
  display: grid;
  grid-template-columns: 300px auto;
  column-gap: 1rem;

  margin-bottom: 2rem;

  .image-container {
    display: flex;
    justify-content: center;
    // flex-direction: column;
    .type {
      text-transform: capitalize;
    }
    .image {
      display: block;
      height: 100%;
      max-height: 400px;
      max-width: 100%;

      width: auto;
      object-fit: contain;
    }
  }

  .individual-scores {
    td,
    th {
      font-size: 14px;
    }
  }
}
