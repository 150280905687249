input {
  padding: 0.375rem;
  border-radius: 4px;
  font-size: 1rem;
}
input[type="month" i] {
  font-size: 1rem;
  font-weight: 500;
  border-radius: 4;
}
