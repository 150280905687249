@import "./../../assets/style/variables/colors";

.physician-panel {
  table {
    font-family: arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    position: relative;
    // height: 300px;
    // overflow-y: scroll;
    // display: block;
    tr {
      background-color: #fff;
    }
    // tr:nth-child(even) {
    //   background-color: #EEE0F4;
    // }
  }
  tr {
    z-index: 99;
  }
  td,
  th {
    border: 0.5px solid #dddddd;
    text-align: center;
    font-size: 1rem;
    padding: 0.5rem;
  }
}

.regimen-date-grp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.summary-arrow-grp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fixedHeaderAndBody {
  //   position: fixed;
  background-color: $primary;
  color: #fff;
}
.date-section {
  min-width: 6rem;
}
.first-table {
  // color: red;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}
.second-table {
  position: relative;
  &::-webkit-scrollbar {
    display: block;
  }
}
.second-table-scroll {
  width: 100%;

  position: absolute;
  left: var(--second-table-offset, 0);
  top: 100%;
  width: var(--second-table-width, 100%);

  overflow-x: auto;
  overflow-y: hidden;
  .second-table-placeholder {
    width: var(--second-table-content-width, 2000px);
    height: 1px;
  }
}
.arrows {
  padding: 1rem 1rem 1rem 1rem;
}

.table-y-scroll {
  max-height: 300px;
  overflow-y: auto;
  // white-space: nowrap;

  display: block;
  &::-webkit-scrollbar {
    //  display: none;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: transparent;
  }
}
.table-y-scroll-second {
  max-height: 300px;
  overflow: hidden;
  // white-space: nowrap;

  display: block;
  &::-webkit-scrollbar {
    //  display: none;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: transparent;
  }
}

.table_1_cell {
  height: var(--date-cell);
}

$icon-dimension: 35px;
.calender-icon {
  background: $primary;
  width: $icon-dimension;
  height: $icon-dimension;
  border-radius: 50%;
  display: flex;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  color: white;
  margin: auto;
  padding: 10px;

  &-svg {
    width: auto;
    height: 20px;
  }
}

.date-pick-container {
  display: flex;
  justify-content: space-between;
  width: var(--summary-table-width);
  min-width: 90vw;
  max-width: 90vw;
  width: 100%;
  padding: 1rem;
  margin: auto;
  align-items: center;
}

.legend {
  display: flex;

  .legendItem {
    display: flex;
    margin-right: 1rem;
    align-items: center;
    font-size: 1rem;

    .calender-icon {
      margin-right: 1rem;
    }

    &:last-child {
      margin-right: unset;
    }
  }
}

.treatment-name {
  width: 30ch;
}

.date-header {
  height: var(--header-height);
}

.table-dates td {
  padding: 0.5rem;
}

.dates {
  max-width: 500px;
  overflow-x: scroll;
}

.treatment-arrow {
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.summmary_table_header {
  color: $primary;
  font-weight: bold;
  font-size: 1.4rem;
}
.table-treatment {
  direction: rtl;
  &::-webkit-scrollbar {
    display: block;
  }
}

.action-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.badge-error-status {
  padding: 4px 12px;
  background: $error;
  border-radius: 4px;
  cursor: pointer;
}
.badge-warning-status {
  padding: 4px 12px;
  background: $status-warning-base;
  border-radius: 4px;
  cursor: pointer;
}

.badge-success-status {
  padding: 4px 12px;
  background: $status-success-base;
  border-radius: 4px;
  cursor: pointer;
}
